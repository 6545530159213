import React, { useState, useLayoutEffect } from "react"
import { graphql } from "gatsby"
import { format } from "date-fns"
import PropTypes from "prop-types"
import { Layout, TemplateHeading, Banner, Socials } from "../../components"
import * as S from "./article.styles"

const ArticleTemplate = ({ data }) => {
  const [thereIsKeyTakeAways, setKeyTakeAways] = useState()
  const article = data.contentfulArticle
  const formatedDate = format(new Date(article.createdAt), "MM.dd.yyyy")
  useLayoutEffect(() => {
    const images =
      typeof window !== "undefined" &&
      document.getElementsByClassName("article_images")
    if (article?.keyTakeAways?.json) {
      setKeyTakeAways(true)
    } else if (images.length > 0) {
      images[images.length - 1].style.marginBottom = "0rem"
    }
  }, [])
  return (
    <Layout
      title={article.title}
      description={article.metaDescription}
      image={article?.thumbnail?.fluid?.src}
    >
      <S.Grid
        style={{ paddingBottom: `${thereIsKeyTakeAways ? "0rem" : "5rem"}` }}
      >
        <TemplateHeading
          heading={`${article.author} | ${formatedDate}`}
          title={article.title}
        />
        <Banner image={article.thumbnail} />
        <S.SocialWrapper>
          <Socials
            title={article.title}
            description={article.metaDescription}
            hashtags=""
          />
        </S.SocialWrapper>
        <S.ArticleBody text={article.body.json} />
        {article.keyTakeAways && (
          <>
            <S.KeyTakeAwaysLabel>Key takeaways</S.KeyTakeAwaysLabel>
            <S.KeyTakeAway text={article.keyTakeAways.json} />
          </>
        )}
      </S.Grid>
    </Layout>
  )
}

ArticleTemplate.propTypes = {
  data: PropTypes.shape({
    contentfulArticle: PropTypes.object,
  }).isRequired,
}

export default ArticleTemplate

export const pageQuery = graphql`
  query ArticleBySlug($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      id
      slug
      title
      author
      createdAt
      thumbnail {
        description
        fluid(maxWidth: 1312, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      body {
        json
      }
      metaDescription
      keyTakeAways {
        json
      }
    }
  }
`
