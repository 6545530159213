import media from "styled-media-query"
import styled from "styled-components"
import {
  TemplateHeading,
  Banner,
  FormatRichText,
  Socials,
} from "../../components"

export const ArticleBody = styled(FormatRichText)``
export const KeyTakeAway = styled(FormatRichText)``
export const SocialWrapper = styled.div``
export const KeyTakeAwaysLabel = styled.h1``

export const Grid = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-auto-rows: auto;
  ${TemplateHeading}, ${ArticleBody}, ${KeyTakeAway} {
    padding: 2.5rem 1rem;
  }
  ${Banner} {
    height: 320px;
    border-top: ${(props) => props.theme.borderStyle};
    border-bottom: ${(props) => props.theme.borderStyle};
    img {
      border-bottom: ${(props) => props.theme.borderStyle};
    }
  }
  ${SocialWrapper} {
    padding: 1rem 1rem 0rem 1rem;
    grid-row: 3 / span 1;
    height: 3.75rem;
    display: flex;
    align-items: center;
  }
  ${ArticleBody} {
    display: flex;
    align-items: flex-start;
    padding-top: 1.5rem;
  }
  ${KeyTakeAway} {
    background: ${(props) => props.theme.ui03};
    align-items: start;
  }
  ${KeyTakeAwaysLabel} {
    color: #525252;
    padding: 2rem 1rem 0rem 1rem;
    background: ${(props) => props.theme.ui03};
    font-size: 0.75rem;
    line-height: 0.9rem;
    font-family: ${(props) => props.theme.fontStyleTertiary};
    border-top: ${(props) => props.theme.borderStyle};
  }
  ${media.greaterThan("medium")`
  /* screen width is less than 768px (medium) */
  grid-template-columns: 1fr 480px 1fr;
  ${TemplateHeading} {
    padding: 0rem;
    grid-column: 2 / end;
    margin-top: 4rem;
    margin-bottom: 2rem;
    > * {
      max-width: 62.5%;
    }
  }
  ${SocialWrapper} {
    grid-row: 3 / span 1;
    padding: 2rem;
    height: max-content;
  }
  ${Socials} {
    max-width: 160px;
  }
  ${ArticleBody} {
    padding: 2rem 0rem 0rem 0rem;
    figure:nth-child(2) {
      padding-right: 2rem;
    }
    p:empty {
      display: none;
    }
  }
  ${Banner} {
    grid-column: 1 / end;
  }
  ${KeyTakeAwaysLabel} {
    padding-left: 2rem;
  }
  
  ${KeyTakeAway} {
    border-top: ${(props) => props.theme.borderStyle};
    padding: 2rem 0rem 5rem 0rem;
  }
  ${ArticleBody}, ${KeyTakeAway} {
    grid-column: 2 / end;
    align-items: start;
    h1, h2, h3, h4, h5, h6, p {
      max-width: 62.5%;
    }
  }
`}
  ${media.between("medium", "1055px")`
    ${ArticleBody}, ${KeyTakeAway}  {
      padding-right: 2rem;
      h1, h2, h3, h4, h5, h6, p {
        max-width: 100%;
      }
    }
    ${TemplateHeading} {
      padding-right: 2rem;
      > * {
        max-width: 100%;
      }
    }
  `}
`
